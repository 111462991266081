import { getAccount } from '@wagmi/core';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';

import { PUBLIC_WALLETCONNECT_PROJECT_ID, PUBLIC_ENVIRONMENT_STAGE } from '$env/static/public';
import { hardhat } from 'viem/chains';
import { createPublicClient, defineChain, http, type Chain } from 'viem';
import { IEnvironment } from '../../types';

const projectId = PUBLIC_WALLETCONNECT_PROJECT_ID;

export const taikoHekla = defineChain({
  // Should be in viem package 2.9.18
  id: 167009,
  name: 'Taiko Hekla (Alpha-7 Testnet)',
  network: 'tko-hekla',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.hekla.taiko.xyz'],
    },
    public: {
      http: ['https://rpc.hekla.taiko.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'taikoscan',
      url: 'https://hekla.taikoscan.network',
    },
  },
  testnet: true,
});

export const taikoMainnet = defineChain({
  id: 167000,
  name: 'Taiko Mainnet',
  network: 'tko',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.taiko.xyz'],
    },
    public: {
      http: ['https://rpc.taiko.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'taikoscan',
      url: 'https://taikoscan.io/',
    },
  },
  testnet: false,
});

const getNonProdChainConfig = () => {
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.LOCAL) return hardhat;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.TESTNET) return taikoHekla;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.STAGING) return taikoHekla;
  return taikoHekla;
};

export const chains: [Chain, ...Chain[]] = [
  PUBLIC_ENVIRONMENT_STAGE !== IEnvironment.PRODUCTION ? getNonProdChainConfig() : taikoMainnet,
];

const metadata = {
  name: 'Hana Finance',
  description: 'Hana Finance',
  url: 'https://www.hana.finance/',
  icons: ['https://avatars.githubusercontent.com/u/99078433'],
};

// export const wagmiConfig = defaultWagmiConfig({ projectId, chains, metadata, transports: { [hardhat.id]: transport } });
export const wagmiConfig = defaultWagmiConfig({
  projectId,
  chains,
  metadata,
  enableEIP6963: true,
  enableWalletConnect: true,
  enableEmail: false,
  enableInjected: true,
});

export const web3Modal = createWeb3Modal({
  wagmiConfig,
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-font-family': 'Montserrat',
    '--w3m-color-mix-strength': 10,
    '--w3m-accent': '#CD0682',
    '--w3m-color-mix': '#FFFFFF',
  },
  allWallets: 'SHOW',
});

export const getPublicClient = createPublicClient({
  chain: wagmiConfig.chains[0],
  transport: http(),
});

export function getCurrentAddressOrNull(): `0x${string}` | undefined {
  const { address } = getAccount(wagmiConfig);
  return address;
}
