export enum ToastType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface Toast {
  id?: number;
  type: 'info' | 'success' | 'error';
  dismissible?: boolean;
  timeout?: number;
  message?: string;
  txnHash?: string;
}

export type ScreenMode = 'desktop' | 'mobile';

export enum IEnvironment {
  LOCAL = 'LOCAL',
  TESTNET = 'TESTNET',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}
