import { PUBLIC_ADDRESS_OVERRIDES } from '$env/static/public';

const isAddressOverride = PUBLIC_ADDRESS_OVERRIDES === 'true';

type AddressOrNull = `0x${string}` | null;
type IOverrideAddress = {
  167000: AddressOrNull;
  167009: AddressOrNull;
};

export const TOKEN_ADDRESSES: { [key: string]: IOverrideAddress } = {
  weth: {
    167000: '0xa51894664a773981c6c112c43ce576f315d5b1b6',
    167009: '0x115d2e1e3C16765b6858AEB853Ef4EF8D8c45051',
  },
  wbtc: {
    167000: null,
    167009: '0x367F1F510036fD3feccBfa5eBD162e9dD244E5E8',
  },
  usdc: {
    167000: '0x07d83526730c7438048d55a4fc0b850e2aab6f0b',
    167009: '0x5afCBf3f53eaa3A31C972A058A87e4182b746f44',
  },
  usdt: {
    167000: null,
    167009: '0x5288fF7D632A85bcC0f214E6c49f3C2E863CFeC6',
  },
  taiko: {
    167000: '0xa9d23408b9ba935c230493c40c73824df71a0975',
    167009: '0xa2c619922c282c51b80d6c57ccc3ceac701e7f10',
  },
  usdc_e: {
    167000: '0x19e26b0638bf63aa9fa4d14c6baf8d52ebe86c5c',
    167009: '0xb41ffa36e4fb7655a81a32c4328ef53850ddfc75',
  },
  hana: {
    167000: null,
    167009: '0x7f4DEc9C71Cdb4b8b731dec592153B7f39049870',
  },
  earlyHANA: {
    167000: null,
    167009: '0xD2877dDbf7A412fe3c998091496b3186AdCFB6E0',
  },
};

export const ENVIRONMENT_ADDRESS = {
  // poolProxyTaikoAddress: PUBLIC_POOL_PROXY_TAIKO_ADDRESS ?? '0x4aB85Bf9EA548410023b25a13031E91B4c4f3b91',
  // wrappedTokenGatewayV3Address: PUBLIC_WRAPPED_TOKEN_GATEWAY_V3_ADDRESS ?? '0xB9eD09af341a59c05c8AaE584172e8dCc1E828b6',
  // incentivesProxyAddress: PUBLIC_INCENTIVES_PROXY_ADDRESS ?? '0x475B0F69299c388211aBE740537e5E282BFC0d40',
  // faucetTaikoAddress: PUBLIC_FAUCET_TAIKO_ADDRESS ?? '0x31Ece3f656187EC517bfD01a13E99795431F6960',
  // wethPoolAddress: PUBLIC_WETH_POOL_ADDRESS ?? '0xA51894664A773981C6C112C43ce576f315d5b1B6',
};

const HanaAddresses_Hekla_Testnet: { [key: string]: AddressOrNull } = {
  poolProxyTaikoAddress: '0x0cBcF9Ab151be74A9898e1Eef6510f016449C462',
  wrappedTokenGatewayV3Address: '0x8e5479CD521ce9fC8B6dd0bD338D0EC0060b0E0A',
  incentivesProxyAddress: '0x581424706d992fbea3075ba4babd63191D500031',
  faucetTaikoAddress: '0x31Ece3f656187EC517bfD01a13E99795431F6960',

  // Normal Token
  wethTestnetMintableErc20TaikoAddress: '0x115d2e1e3C16765b6858AEB853Ef4EF8D8c45051',
  wbtcTestnetMintableErc20TaikoAddress: '0xe0f3D4A72942AB6D80d619E5B3925Adb2558570D',
  usdcTestnetMintableErc20TaikoAddress: '0xe692e8103585ec326C118EA4e15861D9B578f527',
  usdtTestnetMintableErc20TaikoAddress: '0xA9af718469b688Fee02d230e8366D074EB128c6a',
  tkoTestnetMintableErc20TaikoAddress: '0x7bFBF87b75A44c7d57bc6851bE62339D55f77f5D',

  // aToken
  wethATokenTaikoAddress: '0xd10F07Db1D4f87de4Ee4E84DAf3888a6256d8Eb6',
  wbtcATokenTaikoAddress: '0x0B02F844FF5b51a672E69690C5Ec419d9A88ADDd',
  usdcATokenTaikoAddress: '0x135ba86b8D6bCC085c5C1BE9cb424A2a34aDd27C',
  usdtATokenTaikoAddress: '0xb0389CBad19D2CD6B2FdE72520aDF6312f21b050',
  tkoATokenTaikoAddress: '0x818Fb2Accd67d9beF8A64D96CB58538387fE0178',

  // vToken
  wethVariableDebtTokenTaikoAddress: '0x10eFbd03ed584C2585637517832E2a41B6c27118',
  wbtcVariableDebtTokenTaikoAddress: '0x3e04162e774b035e5b4f79da7B62F7621F3c5ba2',
  usdcVariableDebtTokenTaikoAddress: '0xa074c87d7c3DB99C1d343e61ADF156Dd7D2aFaA6',
  usdtVariableDebtTokenTaikoAddress: '0x1782738e4af3BDE16F0f5054655EF302f28e3C62',
  tkoVariableDebtTokenTaikoAddress: '0x3664E1533d67eB3Be4DeaCDD8d1F888b9e199077',
};

const HanaAddresses_Hekla_Mock_Testnet: { [key: string]: AddressOrNull } = {};

export const overrideAddresses: { [key: string]: IOverrideAddress } = {
  poolProxyTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.poolProxyTaikoAddress : null,
  },
  wrappedTokenGatewayV3Address: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.wrappedTokenGatewayV3Address : null,
  },
  incentivesProxyAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.incentivesProxyAddress : null,
  },
  faucetTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.faucetTaikoAddress : null,
  },

  // Normal Token
  wethTestnetMintableErc20TaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.wethTestnetMintableErc20TaikoAddress : null,
  },
  wbtcTestnetMintableErc20TaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.wbtcTestnetMintableErc20TaikoAddress : null,
  },
  usdcTestnetMintableErc20TaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.usdcTestnetMintableErc20TaikoAddress : null,
  },
  usdtTestnetMintableErc20TaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.usdtTestnetMintableErc20TaikoAddress : null,
  },
  tkoTestnetMintableErc20TaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.tkoTestnetMintableErc20TaikoAddress : null,
  },
  earlyHanaTestnetMintableErc20RewardTaikoAddress: {
    167000: null,
    167009: null,
  },

  // aToken
  wethATokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.wethATokenTaikoAddress : null,
  },
  wbtcATokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.wbtcATokenTaikoAddress : null,
  },
  usdcATokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.usdcATokenTaikoAddress : null,
  },
  usdtATokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.usdtATokenTaikoAddress : null,
  },
  tkoATokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.tkoATokenTaikoAddress : null,
  },

  // vToken
  wethVariableDebtTokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.wethVariableDebtTokenTaikoAddress : null,
  },
  wbtcVariableDebtTokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.wbtcVariableDebtTokenTaikoAddress : null,
  },
  usdcVariableDebtTokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.usdcVariableDebtTokenTaikoAddress : null,
  },
  usdtVariableDebtTokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.usdtVariableDebtTokenTaikoAddress : null,
  },
  tkoVariableDebtTokenTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.tkoVariableDebtTokenTaikoAddress : null,
  },

  poolDataProviderTaikoAddress: {
    167000: null,
    167009: isAddressOverride ? HanaAddresses_Hekla_Testnet.poolDataProviderTaikoAddress : null,
  },
};
