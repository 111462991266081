import type { MarketConfig, MarketData } from '$libs/market/types';

import { getChainId, type Config } from '@wagmi/core';
import TTKO_ICON from '../public/images/Coin=TAIKO.svg';
import USDC_ICON from '../public/images/Coin=USDC.svg';
import USDT_ICON from '../public/images/Coin=USDT.svg';
import type { ValidChainIds } from '$libs/chain';
import WBTC_ICON from '../public/images/Coin=BTC.svg';
import WETH_ICON from '../public/images/Coin=ETH.svg';
import HanaIcon from '$images/hana-icon-white.svg';
import { tokenConfig } from './tokens';
import {
  taikoATokenTaikoAddress,
  taikoVariableDebtTokenTaikoAddress,
  // tkoATokenTaikoAddress,
  // tkoVariableDebtTokenTaikoAddress,
  usdcATokenTaikoAddress,
  usdcEATokenTaikoAddress,
  usdcEVariableDebtTokenTaikoAddress,
  usdcVariableDebtTokenTaikoAddress,
  // usdtATokenTaikoAddress,
  // usdtVariableDebtTokenTaikoAddress,
  // wbtcATokenTaikoAddress,
  // wbtcVariableDebtTokenTaikoAddress,
  wethATokenTaikoAddress,
  wethVariableDebtTokenTaikoAddress,
} from '../generated';
import { wagmiConfig } from '$libs/wagmi';
import { TOKEN_ADDRESSES, overrideAddresses } from '../environment.config';

export const getReserveAssets = (wagmiConfig: Config): MarketConfig => {
  const chainId: ValidChainIds = getChainId(wagmiConfig) as ValidChainIds;

  return {
    eth: {
      name: 'Ether',
      symbol: 'ETH',
      address: TOKEN_ADDRESSES.weth[chainId] as string,
      aTokenAddress: overrideAddresses.wethATokenTaikoAddress[chainId] ?? wethATokenTaikoAddress[chainId],
      vTokenAddress:
        overrideAddresses.wethVariableDebtTokenTaikoAddress[chainId] ?? wethVariableDebtTokenTaikoAddress[chainId],
      decimals: 18,
      image: WETH_ICON,
      enabled: true,
      displayDecimal: 4,
    },
    wbtc: {
      name: 'Wrapped Bitcoin',
      symbol: 'WBTC',
      address: TOKEN_ADDRESSES.wbtc[chainId] as string,
      aTokenAddress: overrideAddresses.wbtcATokenTaikoAddress[chainId] ?? '',
      // aTokenAddress: overrideAddresses.wbtcATokenTaikoAddress[chainId] ?? wbtcATokenTaikoAddress[chainId],
      // vTokenAddress:
      //   overrideAddresses.wbtcVariableDebtTokenTaikoAddress[chainId] ?? wbtcVariableDebtTokenTaikoAddress[chainId],
      vTokenAddress: overrideAddresses.wbtcVariableDebtTokenTaikoAddress[chainId] ?? '',
      decimals: 8,
      image: WBTC_ICON,
      enabled: true,
      displayDecimal: 4,
    },
    usdc: {
      name: 'USD Coin',
      symbol: 'USDC',
      address: TOKEN_ADDRESSES.usdc[chainId] as string,
      aTokenAddress: overrideAddresses.usdcATokenTaikoAddress[chainId] ?? usdcATokenTaikoAddress[chainId],
      vTokenAddress:
        overrideAddresses.usdcVariableDebtTokenTaikoAddress[chainId] ?? usdcVariableDebtTokenTaikoAddress[chainId],
      decimals: 6,
      image: USDC_ICON,
      enabled: true,
      displayDecimal: 2,
    },
    usdt: {
      name: 'USD Tether',
      symbol: 'USDT',
      address: TOKEN_ADDRESSES.usdt[chainId] as string,
      // aTokenAddress: overrideAddresses.usdtATokenTaikoAddress[chainId] ?? usdtATokenTaikoAddress[chainId],
      aTokenAddress: overrideAddresses.usdtATokenTaikoAddress[chainId] ?? '',
      // vTokenAddress:
      //   overrideAddresses.usdtVariableDebtTokenTaikoAddress[chainId] ?? usdtVariableDebtTokenTaikoAddress[chainId],
      vTokenAddress: overrideAddresses.usdtVariableDebtTokenTaikoAddress[chainId] ?? '',
      decimals: 6,
      image: USDT_ICON,
      enabled: true,
      displayDecimal: 2,
    },
    taiko: {
      name: 'Taiko',
      symbol: 'TAIKO',
      address: TOKEN_ADDRESSES.taiko[chainId] as string,
      aTokenAddress: overrideAddresses.tkoATokenTaikoAddress[chainId] ?? taikoATokenTaikoAddress[chainId],
      vTokenAddress:
        overrideAddresses.tkoVariableDebtTokenTaikoAddress[chainId] ?? taikoVariableDebtTokenTaikoAddress[chainId],
      decimals: 18,
      image: TTKO_ICON,
      enabled: true,
      displayDecimal: 2,
    },
    usdc_e: {
      name: 'USD Coin (Stargate)',
      symbol: 'USDC.e',
      address: TOKEN_ADDRESSES.usdc_e[chainId] as string,
      aTokenAddress: usdcEATokenTaikoAddress[chainId],
      vTokenAddress: usdcEVariableDebtTokenTaikoAddress[chainId],
      decimals: 6,
      image: USDC_ICON,
      enabled: true,
      displayDecimal: 2,
    },
    earlyHana: {
      name: 'earlyHANA',
      symbol: 'earlyHANA',
      address: TOKEN_ADDRESSES.earlyHANA[chainId] as string,
      aTokenAddress: '',
      vTokenAddress: '',
      decimals: 18,
      image: HanaIcon,
      enabled: false,
      displayDecimal: 2,
    },
    hana: {
      name: 'Hana',
      symbol: 'HANA',
      address: TOKEN_ADDRESSES.hana[chainId] as string,
      aTokenAddress: '',
      vTokenAddress: '',
      decimals: 18,
      image: HanaIcon,
      enabled: false,
      displayDecimal: 2,
    },
  };
};

export const markets: MarketConfig = getReserveAssets(wagmiConfig);

export const getMarketToken = (address: string) => {
  return Object.values(markets).find((_market) => _market.address.toLowerCase() === address.toLowerCase());
};
