import { writable } from 'svelte/store';

const ITEM_TAG = 'HanaFinance_DoNotShowSocialBanner';

const GET_TEMP_FLAG = () => {
  return !!sessionStorage.getItem(ITEM_TAG);
};

const GET_PERM_FLAG = () => {
  return !!localStorage.getItem(ITEM_TAG);
};

const SET_TEMP_FLAG = () => {
  sessionStorage.setItem(ITEM_TAG, 'true');
};

const SET_PERM_FLAG = () => {
  localStorage.setItem(ITEM_TAG, 'true');
};

export const GET_FLAG = () => {
  if (!GET_PERM_FLAG()) {
    if (!GET_TEMP_FLAG()) return true;
    return false;
  }
  return false;
};

export const showSocialBanner = writable<boolean>(GET_FLAG());

export const togglePermSocialBanner = () => {
  SET_PERM_FLAG();
  showSocialBanner.update(() => false);
};

export const toggleTempSocialBanner = () => {
  SET_TEMP_FLAG();
  showSocialBanner.update(() => false);
};
